import React from "react";
import ZavetZdarma from "../templates/ZavetZdarmaTemplate";
import NfpomociImg from "../assets/img/NfpomociImg.svg";
import styled from "styled-components";


const TopPanelInner = styled.section`
  border-bottom: 1px solid #DFDFDF;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-top: 5px solid red; */
  background: #2d2a26;



  @media (max-width: 1260px) {
    height: fit-content;
    padding: 20px 0;
  }

  @media (max-width: 580px) {
    padding: 30px 0;
  }


  div {
    @media (max-width: 1260px) {
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: calc(100% - 50px);
    }

    @media (max-width: 580px) {
      display: block;
    }
  }


  img {
    height: 60px;
    position: absolute;
    top: 30px;
    left: calc((100% - 1150px)/2);

    @media (max-width: 1260px) {
      position: static;
    }

    @media (max-width: 580px) {
    }
  }


  p {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #000000;
    color: #fff;
    text-align: center;
    max-width: 610px;
    line-height: 26px;

    @media (max-width: 1260px) {
      text-align: left;
      margin-left: 50px;
    }

    @media (max-width: 580px) {
      margin: 0;
      margin-top: 20px;
    }
  }

`;

const TopPanel = () => {
  return (
    <TopPanelInner>
      <div>
        <img src={NfpomociImg} alt="Nadační fond pomoci logo" />
        <p>Děkujeme, že jste si vybrali Goodbye na sepsání vaší závěti. Ve spolupráci s Nadačním fondem pomoci ji můžete vytvořit zdarma (běžně 2 000 Kč).</p>
      </div>
    </TopPanelInner>
  );
}



const Nfpomoci = () => {

  return (
    <ZavetZdarma
      topPanel={<TopPanel/>} 
      url="nfpomoci"
      charityTitle="Nadační fond pomoci"
      h1="Vytvořte si vlastní platnou závěť online"
      desc={<p>Jsme hrdým partnerem Nadačního fondu pomoci. Pro uplatnění své závěti zdarma zadejte slevový kód “<span>nfpomoci</span>” při platbě.</p>}
    />
  );
}

export default Nfpomoci;
